import * as ProductListingUserEvent from '@ori-events/product-listing-user-event';

/*
--------------------------------------------------------------------------------
asynchronous script loader
--------------------------------------------------------------------------------
*/
(function (window, $) {

    'use strict';

    var self,
        buttonSelector = '.js-async-load',
        $button = null,
        quickShop = '.js-quick-shop',
        productBox = '.ui-product-box',
        buttonData = null,
        error = 'Loading error',
        errorClass = 'async-load-error',
        $error = $('<div />').addClass(errorClass);

    ori.addModule("asyncLoad", {

        /**
        * Initialization.
        */
        init: function (options) {
            self = this;
            $button = $(buttonSelector);
            buttonData = $button.data();

            $button
                .bind('click.asyncLoad', function (e) {
                    var $button = $(this),
                        buttonData = $button.data(),
    					$container = $(buttonData.asyncLoadTarget),
                        $error = $button.parent().prev('.' + errorClass);
                    $error.html($button.attr('data-error-message'));
                    $('body').trigger('asyncLoadEvent', [{
                        $button: $button,
                        url: buttonData.asyncLoadUrl,
                        $container: $container,
                        $error: $error,
                        count: buttonData.asyncLoadCount || 3,
                        length: buttonData.length || $container.find('article').length,
                        total: buttonData.asyncLoadArticlesTotal
                    }]);
                })
                .parent()
                .before($error.clone());

            $('body').bind('asyncLoadEvent', function (e, data) {

                if ($(this).hasClass("asyncLoad-loading"))
                    return;

                var from = 'from=' + data.length,
                    count = '&count=' + data.count,
                    $spinnerBox = $("<div id='spinnerBox'></div>");

                from = (data.url.indexOf('?') < 0 ? '?' : '&') + from;
                $.ajax({
                    url: data.url + from + count,
                    dataType: 'html',
                    context: data.context || this,
                    beforeSend: function () {
                        $(this).addClass('asyncLoad-loading');

                        $spinnerBox.css({ "width": "100%", "height": "60px", "z-index": 2, "position": "relative", "clear": "both" });
                        data.$container.after($spinnerBox);
                        ori.spinner.applyTo("#spinnerBox");
                    },
                    complete: function (jqXHR, textStatus) {
                        var html = jqXHR.responseText,
                            actualLength = data.length + data.count,
                            // save context
                            _this = this;
                        window.setTimeout(function () {
                            $(_this).removeClass('asyncLoad-loading');

                            // removing spinnerbox
                            $("#spinnerBox").remove();

                            if ({ success: 1, notmodified: 1 }[textStatus]) {
                                data.$button.data('length', actualLength);
                                data.$error.removeClass('error-on');
                                if ($.trim(html)) {
                                    var $data = $(html);
                                    $data.hide();

                                    data.$container
                                        .append($data)
                                        .trigger('change');

                                    self.handleQuickBuy($data);
                                    $data.slideDown();
                                }
                                if (actualLength >= data.total) {
                                    data.$button.parent().hide();
                                }
                                ori.cutShort.init();
                                ori.lazyLoad.init(data.$container);

                                const url = new URL(window.location);
                                url.searchParams.set('count', actualLength);
                                window.history.pushState(null, '', url.toString());

                                if (buttonData.productSearch !== "True") {
                                    return;
                                }

                                var customEvent = new CustomEvent(ProductListingUserEvent.Name, {
                                    detail: {
                                        customerId: buttonData.customerId | '-1',
                                        userEvent: ProductListingUserEvent.UserEventType.LoadMore,
                                    },
                                })
                                window.dispatchEvent(customEvent);
                            } else {
                                data.$error.addClass('error-on').removeClass('error-fired');
                                window.setTimeout(function () {
                                    data.$error.addClass('error-fired');
                                }, 25);
                            }
                        }, 150);
                    }
                });
            });
        },

        /*
         * Handles Quick buy button functionality
         * @param  {jQuery} $data Content of product boxes
         */
        handleQuickBuy: function ($data) {
            if (ori.quickShop && $(quickShop).length) {
                var $newItems = $data.find(productBox);

                if ($newItems.length === 0) $newItems = $data.filter(productBox);
                ori.quickShop.productItemQuickBuy($newItems);
                ori.quickShop.bindFigureEvents($newItems.find('.figure'));
            }
        }
    });

})(window, jQuery);
